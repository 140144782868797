<template>
    <div class="log_box">
        <div class="log_header">
            <div class="company">
                <span class="mr_8">快递公司: </span> <span>{{ logData.logisticsCompanyName||'' }}</span>
            </div>
            <div class="express_info">
                <span class="mr_8">快递单号: </span> <span>{{ logData.thirdExpressNumber || '' }}</span>
            </div>
        </div>
        <!-- 第三方物流展示  -->
        <div
            v-if="showThird"
            class="log_content">
            <div
                v-for="(item,index) in logData.data"
                :key="index"
                class="log_item van-hairline--left">
                <img
                    v-if="index===0"
                    :src="require('@img/order/common_a.png')"
                    class="log_icon"
                    alt="">
                <img
                    v-else
                    :src="require('@img/order/common.png')"
                    class="log_icon"
                    alt="">
                <div class="log_info">
                    <div class="info_title">
                        {{ item.statusText }}
                    </div>
                    <div class="info_des">
                        {{ item.context }}
                    </div>
                    <div class="info_time">
                        {{ item.time }}
                    </div>
                </div>
            </div>
        </div>

        <!-- 展示第三方以外的物流信息 -->
        <div
            v-else
            class="log_content">
            <div
                v-for="(item,index) in logData.data"
                :key="index"
                class="log_item van-hairline--left">
                <img
                    v-if="item.status===logData.expressStatus"
                    :src="require(`@img/order/${item.status}_a.png`)"
                    class="log_icon"
                    alt="">
                <img
                    v-else="item.status!==logData.expressStatus"
                    :src="require(`@img/order/${item.status}.png`)"
                    class="log_icon"
                    alt="">
                <div class="log_info">
                    <div class="info_title">
                        {{ item.statusText }}
                    </div>
                    <div class="info_des">
                        {{ item.context }}
                    </div>
                    <div class="info_time">
                        {{ item.time }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import api from '@/api';

export default {
    name: 'OrderLog',
    data() {
        return {
            id: this.$route.params.id,
            showThird: true,
            logData: {},

        };
    },
    created() {
        // 快递状态映射：1:已取消;2:待确认;3:开始打包;4:打包完成;5:待安排配送;6:待取货;7:已取货;8:待签收;9:已签收;10:待自提

        console.log(`${this.id}lid`);
        this.getOrderTrack();
    },
    methods: {
        getOrderTrack() {
            const params = {
                orderId: this.id,
                currentUserId: window.localStorage.getItem('userId'),
            };
            api.getOrderTrack(params).then((res) => {
                if (res.data.deliveryMethod === 'THIRD_PARTY_LOGISTICS') {
                    this.showThird = true;
                } else {
                    this.showThird = false;
                }
                this.logData = res.data;
            });
        },
    },

};
</script>

<style lang="scss" scoped>
.log_box {
    width: 100%;
    .log_header {
        margin-bottom: 8px;
        background: #fff;
        padding: 18px 24px;
        font-size: 16px;
        font-weight: 400;
        color: #333;
        .company {
            margin-bottom: 8px;
            line-height: 22px;
        }
        .express_info {
            line-height: 22px;
        }
        .mr_8 {
            margin-right: 8px;
        }
    }
    .log_content {
        background: #fff;
        padding: 24px 36px;
        .log_item {
            border-left: 1px dashed#babfc9;
            position: relative;
            padding-left: 25px;
            padding-bottom: 16px;
            .log_icon {
                width: 24px;
                height: 24px;
                position: absolute;
                top: 0;
                left: -12px;
                z-index: 1;
            }
            .log_info {
                .info_title {
                    height: 22px;
                    font-size: 16px;
                    font-weight: 500;
                    color: #333;
                    line-height: 22px;
                }
                .info_des {
                    margin: 4px 0 2px;
                    font-size: 14px;
                    font-weight: 400;
                    color: #62636c;
                    line-height: 20px;
                }
                .info_time {
                    height: 17px;
                    font-size: 12px;
                    font-weight: 400;
                    color: #90949e;
                    line-height: 17px;
                }
            }
        }
        .log_item:last-child {
            padding-bottom: 0;
        }
    }
}
</style>
